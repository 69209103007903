<template>
  <div class="l-wrapper">
    <q-scroll-area class="l-grow q-pa-md">
      <div class="column q-col-gutter-sm">
        <q-input outlined filled readonly v-model="dateModel">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="data.selWeek.date" range mask="YYYY-MM-DD">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-input
          outlined
          type="number"
          label="New"
          v-model="data.selWeek.new"
        ></q-input>
        <q-input
          outlined
          type="number"
          label="Exist"
          v-model="data.selWeek.exist"
        ></q-input>
      </div>
      <div v-if="route.params.id > 0" class="q-mt-lg">
        <q-markup-table class="stickyTable" separator="cell">
          <thead>
            <tr>
              <th>No</th>
              <th>Nama</th>
              <th>Exist</th>
              <th>New</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(val, i) in data.selWeek.sales_achievment" :key="i">
              <td class="text-center">{{ i }}</td>
              <td>{{ val.username }}</td>
              <td
                class="text-center"
                :class="{
                  'bg-red-2':
                    parseInt(val.exist) < parseInt(data.selWeek.exist),
                }"
              >
                {{ val.exist }}
              </td>
              <td
                class="text-center"
                :class="{
                  'bg-red-2': parseInt(val.new) < parseInt(data.selWeek.new),
                }"
              >
                {{ val.new }}
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
    </q-scroll-area>
    <q-btn
      class="no-border-radius"
      color="primary"
      label="save"
      v-if="route.params.id < 0"
      @click="addTarget"
    ></q-btn>
    <q-btn
      class="no-border-radius"
      color="primary"
      label="save"
      :disable="!data.selWeek.date?.from && !data.selWeek.date?.to"
      v-else
      @click="saveTarget(data.selWeek)"
    ></q-btn>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import { onMounted } from "vue";
import useWeeklyTarget from "./useWeeklyTarget";
import moment from "moment";
export default {
  setup() {
    const {
      addTarget,
      data,
      route,
      getSalesSummary,
      saveTarget,
      $q,
    } = useWeeklyTarget();

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        if (route.params.id > 0) {
          await getSalesSummary(route.params.id);
        } else {
          data.selWeek.date = { from: null, to: null };
          data.selWeek.new = 5;
          data.selWeek.exist = 10;
        }
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    let dateModel = computed(() => {
      if (data.selWeek.date?.from && data.selWeek.date?.to) {
        let model = `${moment(data.selWeek.date.from).format(
          "DD MMM YYYY"
        )} - ${moment(data.selWeek.date.to).format("DD MMM YYYY")}`;
        return model;
      } else if (typeof data.selWeek.date == "string") {
        return `${moment(data.selWeek.date).format("DD MMM YYYY")}`;
      } else {
        return "-";
      }
    });

    return {
      data,
      addTarget,
      saveTarget,
      route,
      dateModel,
    };
  },
};
</script>
