import { reactive, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import moment from "moment";
export default function useUser() {
  const router = useRouter();
  const route = useRoute();
  const $http = inject("$http");
  const $q = useQuasar();

  let data = reactive({
    weekly_target: [],

    availYear: [],
    selWeek: {},

    curUser: {
      id: 1,
    },
  });

  let getYear = async () => {
    let temp = [];
    let resp = await $http.get(`/weekly-target/year`);
    for (let item of resp.data) {
      temp.push(moment(item.start).format("YYYY"));
    }
    data.availYear = temp;
  };

  let getTarget = async () => {
    let resp = await $http.get(`/weekly-target/list`);
    data.weekly_target = resp.data;
  };

  let addTarget = async () => {
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      data.selWeek.start = data.selWeek.date.from;
      data.selWeek.end = data.selWeek.date.to;
      await $http.post(`/weekly-target/create`, data.selWeek);
      router.push("/weekly-target/list");
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
  };

  let getSalesSummary = async (id) => {
    let resp = await $http.get(`/weekly-target/getsalessummary/${id}`);
    resp.data.date = {};
    resp.data.date.from = resp.data.start;
    resp.data.date.to = resp.data.end;
    data.selWeek = resp.data;
  };

  let saveTarget = async (bundle) => {
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      data.selWeek.start = data.selWeek.date.from;
      data.selWeek.end = data.selWeek.date.to;
      await $http.post(`/weekly-target/update`, bundle);
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
  };

  return {
    data,
    router,
    route,
    getTarget,
    addTarget,
    getSalesSummary,
    saveTarget,
    getYear,
    $q,
  };
}
